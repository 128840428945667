import GTable from "@/components/table";
import http from "@/http";
import moment from "moment";
import { formatDecimal } from "@/resources/util";
import GTooltip from '@/components/tooltip';
import { isMobile } from "../../../../../resources/util";

const columns = [
    {
        title: 'ID',
        key: 'id',
        width: '100px',
        align: 'center',
        dataIndex: 'id',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id,
    },
    {
        title: 'Cliente',
        dataIndex: 'cliente.nome',
        scopedSlots: { customRender: 'cliente' },
    },
    {
        title: 'Descrição',
        width: '30%',
        ellipsis: true,
        dataIndex: 'descricao',
        scopedSlots: { customRender: 'descricao' },
        filters: [
            {
                text: 'Fatura paga',
                value: 'S',
            },
            {
                text: 'Fatura cancelada',
                value: 'C',
            },
            {
                text: 'Fatura pendente',
                value: 'P',
            },
        ],
        filterMultiple: true,
        onFilter: (value, record) => {

            if (record.status === value) return record;
        }
    },
    {
        title: 'Data',
        width: '200px',
        dataIndex: 'data',
        sorter: (a, b) => moment(a.data).diff(moment(b.data)),
        customRender: (text, record) => !text ? 'Não informado.' : `${moment(record.data, 'YYYY-MM-DD').format('DD/MM/YYYY')}`,
    },
    {
        title: 'Valor',
        align: 'right',
        dataIndex: 'valor',
        sorter: (a, b) => a.valor - b.valor,
        customRender: (value, record) => !value ? 'R$ 00,00' : `R$ ${formatDecimal(value)}`,
    },
    {
        title: 'Desconto',
        align: 'right',
        dataIndex: 'valor_desconto',
        sorter: (a, b) => a.valor - b.valor,
        customRender: (value, record) => !value ? null : `${value} %`,
    },
    {
        title: 'Valor total',
        align: 'right',
        dataIndex: 'valor_total',
        sorter: (a, b) => a.valor - b.valor,
        customRender: (value, record) => !value ? 'R$ 00,00' : `R$ ${formatDecimal(value)}`,
    },
    {
        colSpan: 1,
        scopedSlots: { customRender: 'menu' },
    }
];

export default {

    data() {
        return {

            list: [],
            loading: null,
            visibleInfo: false,
            pagination: {

                showSizeChanger: true, current: 1,
                showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} itens`,
                onChange: (page) => this.pagination.current = page
            },
        }
    },
    created() {

        this.load();
    },
    methods: {

        load() {

            this.loading = true;
            http.get('painel/financeiro.listarFaturas').then(({ data }) => {

                this.list = data.items;

            }).catch(err => {

                this.$error({
                    title: 'Desculpe, houve um erro!',
                    content: err.message,
                });

            }).finally(() => this.loading = false)
        },

        handlePrint(id) {

            this.loading = true;

            http.get('painel/financeiro.fatura.obter', { params: { id: id }, responseType: 'blob' }).then(data => {

                let url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));

                this.windowOpen(url);

            }).catch(err => {

                this.$error({

                    title: 'Ops, houve um erro.',
                    content: `${err}`
                });
            }).finally(() => this.loading = false);
        },

        windowOpen(url) {

            if (!window.open(url, '_blank')) {

                this.$warning({

                    title: 'Ops, o navegador está bloqueando popups.',
                    content: h => <div><p>Clique no link para abrir: <a href={url} target="_blank">{url}</a></p></div>
                })
            }
        },
    },
    render() {

        const phone = isMobile();

        const table = {

            props: {

                columns,
                rowKey: 'id',
                dataSource: this.list,
                pagination: this.pagination,
                loading: this.loading,
                scroll: { x: 'max-content' }
            },
            scopedSlots: {

                cliente: (text, record) => (

                    <div>
                        <g-icon icon={['far', 'sign']} fixedWidth style={{ marginRight: '3px' }} />
                        {record.cliente.pessoa === "J" ? (record.cliente.nome_fantasia || record.cliente.razao_social) : record.cliente.nome} <br />
                        <g-icon icon={['far', 'university']} fixedWidth /> {record.organizacao.nome_fantasia}
                    </div>
                ),

                descricao: (text, record) => (

                    <div style="display: flex; justify-content: space-between">
                        <span style="white-space: nowrap; overflow: hidden; width: 400px; text-overflow: ellipsis">
                            {text ? text : 'Não informado.'}
                        </span>
                        {record.status === 'PG' && <a-tag color="green">PAGO</a-tag>}
                        {record.status === 'C' && <a-tag color="red">CANCELADO</a-tag>}
                        {record.status === 'PE' && <a-tag>PENDENTE</a-tag>}
                    </div>
                ),

                menu: (text, record) => (

                    <div style="display: flex; font-size: 18px">
                        <GTooltip title="Visualizar" placement="top">
                            <a-button type="link" onClick={() => this.$router.push({ name: 'faturas-view', params: { id: record.id } })}>
                                <g-icon icon={['fas', 'eye']} />
                            </a-button>
                        </GTooltip>
                        <GTooltip title="Imprimir" placement="top">
                            <a-button type="link" style="color: #7265e6;" onClick={() => this.handlePrint(record.id)}>
                                <g-icon icon={['fas', 'print']} />
                            </a-button>
                        </GTooltip>
                    </div>
                )
            },
        };

        return (

            <div class="view view-body-scrollable">
                <div class="view-header">
                    <div class="view-header-body" style='padding: 16px 14px'>
                        <div class="view-header-start">

                            <g-icon icon={['fas', 'file-invoice-dollar']} />
                            <h1 style='font-size: 26px'>Listagem de Faturas</h1>

                            {
                                !phone ? (
                                    <a-popover title="Informações" placement="rightTop">
                                        <template slot="content">
                                            <div style="max-width: 400px;">
                                                <p>Acompanhe suas faturas.</p>
                                                <p><b>Opções:</b> para as ações disponíveis, utilize os botões no final da linha:</p>
                                                <p><b>Visualizar :</b> Acesse e veja a lista de vistorias cobradas;</p>
                                                <p><b>Imprimir :</b> Clique para imprimir ou fazer download da fatura;</p>
                                                <a-divider />


                                                <p><a-tag color="red">CANCELADO</a-tag>: Fatura cancelada;</p>
                                                <p><a-tag>PENDENTE</a-tag>: Fatura pendente;</p>
                                                <p><a-tag color="green">PAGO</a-tag>: Fatura paga;</p>

                                            </div>
                                        </template>
                                        <span style="margin-left: 10px; cursor: pointer;">
                                            <g-icon icon={['far', 'question-circle']} />
                                        </span>
                                    </a-popover>
                                ) : (
                                    <span
                                        style="margin-left: 10px; cursor: pointer;"
                                        onClick={() => this.visibleInfo = !this.visibleInfo}
                                    >
                                        <g-icon icon={['far', 'question-circle']} style={{ fontSize: '20px' }} />
                                    </span>
                                )
                            }
                        </div>
                    </div>
                </div>
                <a-modal
                    footer={null}
                    title="Informações"
                    visible={this.visibleInfo}
                    onCancel={() => this.visibleInfo = !this.visibleInfo}
                >
                    <p>Acompanhe suas faturas.</p>
                    <p><b>Opções:</b> para as ações disponíveis, utilize os botões no final da linha:</p>
                    <p><b>Visualizar :</b> Acesse e veja a lista de vistorias cobradas;</p>
                    <p><b>Imprimir :</b> Clique para imprimir ou fazer download da fatura;</p>
                    <a-divider />
                    <p><a-tag color="red">CANCELADO</a-tag>: Fatura cancelada;</p>
                    <p><a-tag>PENDENTE</a-tag>: Fatura pendente;</p>
                    <p><a-tag color="green">PAGO</a-tag>: Fatura paga;</p>
                </a-modal>
                <GTable {...table} onEditRow={(rowId) => this.$router.push({ name: 'faturas-view', params: { id: rowId } })} />
            </div>
        )
    }
}